import 'slick-carousel';
import 'magnific-popup';

$('.first_time_how_to').on('click', function () {
  $("#popup_first_time_how_to").load('/first_time_how_to', function (data, status, object) {
    $.magnificPopup.open({
      items: {src: '#popup_first_time_how_to'},
      type: 'inline',
      fixedContentPos: true,
      callbacks: {
        open: function () {
          const $slider = $('.slider');
          if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
          }
          $slider.slick({
            dots: true,
            pauseOnHover: false,
            pauseOnDotsHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            swipe: false,
          });
        },
        afterClose: function () {
          $('.slider').slick('unslick');
        }
      }
    }, 0);
  });
});
